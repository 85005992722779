.modelLearningModel {
  width: 400px !important;
}

.engineLearningModel {
  width: 200px !important;
}

.createdLearningModel {
  width: 200px !important;
}

.configLearningModel {
  width: 400px !important;
}

.borderNone {
  border: none !important;
  background-color: white;
  width: 50px !important;
}

.ant-card-body .table {
  margin: 10px;
  height: 300px;
  width: 100%;
  overflow: auto;
  display: block;
}

.table thead {
  background-color: whitesmoke;
  color: black;
}

.table th, .table td {
  border: 1px solid black;
  width: 180px;
  height: 50px;
  padding-left: 10px;
}

.borderNone {
  background-color: white !important;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  background-image: none;
}

.editInput {
  width: 90% !important;
  background-color: white !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.hide {
  display: none;
}
