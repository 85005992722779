.announcement-information-reload-items {
  margin-bottom: 10px;
}

.announcement-not-link-item {
  padding-left: 15px;
}

div.ant-table-wrapper.announcement-table {

  .ant-table-body {
    max-height: 270px;
    overflow-y: scroll;
  }

  .announcement-type {
    width: 150px;
  }

  .announcement-date {
    width: 450px;
  }
}

/* ウインドウ幅が500px以下のとき、モバイル端末用のCSSを適用 */
@media screen and (max-width : 500px) {

  .information-detail {
    font-size: x-small;
  }

  div.announcement-link-item .announcement-link-button {
    font-size: 8px;
    padding-left: 2%;
  }

  .announcement-not-link-item {
    padding-left: 2%;
    white-space: nowrap;
  }

  div.announcement-container {

    div.announcement-information-reload-items {
      margin-bottom: 5px;
      font-size: smaller;

      .announcement-reload-button {
        display: none;
      }
    }

    .announcement-table .ant-table-body {
      max-height: 30%;
      white-space: nowrap;
    }

    .ant-table-content {
      font-size: 8px;
    }

    .ant-table-header {
      padding-bottom: 10px;
      margin-right: -16px;
      overflow-y: scroll;
    }

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td {
      padding: 5px;
      height: 43px;
    }
  }

  div.ant-table-wrapper.announcement-table {

    .ant-table-body {
      max-height: 20vh !important;
    }

    .announcement-type {
      width: 17vw;
    }

    .announcement-date {
      width: 28vw;
    }
  }

  .release-note-modal .ant-modal-title {
    font-size: x-small;
  }

  div.ant-modal.release-note-modal {
    margin-right: 0;

    .ant-modal-body {
      height: 50vh;
    }

    .announcement-detail {
      /* タイトルの'height:55'とbodyの'padding:48'を減算 */
      height: calc(50vh - 103px);
    }
  }
}

/* モバイル端末横向き */
@media screen and (max-height : 500px) and (orientation: landscape) {

  .announcement-reload-button {
    display: none;
  }

  div.announcement-link-item .announcement-link-button {
    font-size: 12px;
    padding-left: 2%;
  }

  .announcement-not-link-item {
    padding-left: 2%;
  }

  div.ant-table-wrapper.announcement-table {

    .announcement-type {
      width: 17vw;
    }

    .announcement-date {
      width: 28vw;
    }
  }

  div.ant-modal.release-note-modal {
    margin-right: 0;

    .ant-modal-body {
      max-height: 60vh;
    }

    .announcement-detail {
      /* タイトルの'height:55'とbodyの'padding:48'を減算 */
      height: calc(70vh - 103px);
    }
  }

  div.announcement-container {
    .ant-table-content {
      font-size: 12px;
    }

    .ant-table-fixed-header .ant-table-scroll .ant-table-header {
      padding-bottom: 10px;
      margin-right: -16px;
      overflow-y: scroll;
    }
  }
}
