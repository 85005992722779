.contain-thumbnail {
  object-fit: contain;
  /* BOX内の画像配置     */
  width: 200px;
  /* BOXの幅             */
  height: 150px;
  /* BOXの高さ           */
}

.file-detail-view-file-name {
  /* 幅の最大値（全角22文字：ファイルアイコンの幅も含む） */
  max-width: 22em;
  /* 幅が最大値以上の場合三点リーダーにする */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
