.searched-financial-report-table-filter-dropdown {
  padding: 8px;
}

.searched-financial-report-table-search-button {
  margin-right: 8px;
  width: 90px;
}

.searched-financial-report-table-reset-button {
  width: 90px;
}

.searched-financial-report-table-site-name-input {
  margin-bottom: 8px;
  display: block;
  z-index: 1;
}
