.mobile-display-measurement-search-options {
  .ant-collapse-item .ant-collapse-header {
    padding-left: 35px;
    pointer-events: none;

    i.ant-collapse-arrow {
      pointer-events: auto;
      left: 10px;
    }

    button,
    input {
      pointer-events: auto;
    }
  }

  .ant-row,
  .ant-row-flex {
    margin: 2% 0 !important;
    padding-bottom: 5px;

    .ant-col {
      height: 100%;
      /* 「表示」ボタンと「条件クリア」ボタンの余白調整 */
      padding: 0 !important;
      vertical-align: middle;

      h3 {
        font-size: 1.3em;
        margin: auto;
      }
    }
  }

  .ant-collapse-content-box {
    padding-left: 35px;

    .ant-row {
      padding-bottom: 5px;
    }
  }
}
