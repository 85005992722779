div.data-collection {
  width: 100%;
  display: flex;
}

div.data-collection div.asset-table {
  width: 416px;
  padding-top: 8px;
  margin-right: 10px;
}

div.data-collection div.chart-container {
  width: 100%;
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
  background-color: white;
}

div.target-selector {
  display: block;
  margin-bottom: 20px;
}
