body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  font-family: 'メイリオ';
}

.ant-layout-header img {
  margin-bottom: 6px;
  vertical-align: middle;
  width: 80px;
  height: 50px;
}

.ant-layout-header h2 {
  color: #fff;
  display: inline-block;
  margin-left: 12px;
  font-family: "メイリオ";
}

div.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.2);
}

div.ant-modal {
  margin: 0 30px 0 auto;
  font-family: "メイリオ";
}

.menu-name,
.menu-explanation {
  color: #262626;
  margin-left: 10px;
}

.menu-name {
  font-weight: 500;
}

/* モバイル端末（iPhone用フォント対応） */
@media (max-width : 500px),
(max-height : 500px) and (orientation: landscape) {

  .main,
  .ant-layout-header h2,
  div.ant-modal,
  .ant-list span {
    font-family: 'メイリオ', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
}
