div.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.login-form {
  width: 500px;
  height: 280px;
  padding: 40px;
  box-shadow: 2px 2px 4px gray;
}

div.logo-area {
  text-align: center;
}

div.login-form img.logo {
  width: 320px;
  height: 100px;
  margin-bottom: 20px;
}

div.login-form-item-format {
  display: flex;
  /* justify-content: center; */
  margin: 0 0 24px !important;
}

div.ant-row.ant-form-item.ant-form-item-with-help {
  margin-bottom: 5px !important;
}

div.login-form-items .ant-form-item-control {
  width: 280px;
}

div.login-form-buttons {
  text-align: center;
}

/* ウインドウ幅が500px以下のとき、モバイル端末用のCSSを適用 */
@media screen and (max-width : 500px) {

  div.login-form {
    width: 90%;
    height: auto;
    padding: 5%;
  }

  div.login-form img.logo {
    width: 80%;
    height: auto;
    margin-bottom: 20px;
  }

  div.login-form-item-format {
    margin: 0 0 16px !important;
  }

  div.login-form-items .ant-form-item-label {
    width: 45%;
    padding-bottom: 0;
    display: flex;
    align-items: center;

    /* 画面幅によってはant-designの挙動で消えるため固定表示 */
    .ant-form-item-required::after {
      content: ':';
      top: -0.5px;
      margin: 0 8px 0 2px;
      display: inline-block;
    }
  }

  div.login-form-items .ant-form-item-control {
    width: 100%;
  }
}
