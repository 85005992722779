.equipment-information-table-filter-dropdown {
  padding: 8px;
}

.equipment-information-table-input {
  margin-bottom: 8px;
  display: block;
  z-index: 1;
}
.equipment-information-table-search-button {
  margin-right: 8px;
  width: 90px;
}
.equipment-information-table-reset-button {
  width: 90px;
}
