.inspection-result-list-container {
  margin: 10px;
}

div.inspection-result-warning-message {
  width: 400px;
  margin: 3px 0px 0px 0px;
  padding-left: 4px;
}

div.inspection-result-edit-button-group {
  width: 200px;
  margin: 10px 0px 0px auto;
  text-align: right;
}
