/** データ登録タイトルコンテナ */

div.common-registration-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

div.common-registration-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

div.common-registration-margin {
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}
