.om-dashboard-measurement-point-items {
  background-color: white;
  padding: 10px 180px;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #c9caca;
}

.om-dashboard-measurement-point-title {
  margin-bottom: 2px;
  border-radius: 3px;
  padding: 5px 5px;
  border: solid 1px white;
  background-color: #c9caca;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  width: 100%;
}

.om-dashboard-red-style-circle {
  color: red;
}

.om-dashboard-yellow-style-circle {
  color: rgb(245, 178, 21);
}
