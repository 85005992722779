.mobile-om-dashboard-tab-items {
  z-index: 10;

  .ant-tabs-ink-bar {
    background-color: #f0f2f5;
  }

  .ant-tabs-bar {
    border-bottom: none;
    margin: 0;
  }

  .ant-tabs-nav .ant-tabs-tab {
    pointer-events: none;
  }

  .ant-tabs-nav-container {
    height: 10px;
  }
}
