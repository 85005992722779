div.managed-facility-card-cover div.edit-button-group {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: rgba(255, 255, 255, 0.4);
}

.add-managed-facility-button {
    position: relative;
    float: right;
    bottom: 12px;
}

/* ローカル環境でAmplifyとフォントを揃えるための暫定定義 */
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    ;
}
