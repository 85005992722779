.mobile-measurement-point-red {
  color: #ff0000;
}

.mobile-measurement-point-yellow {
  color: #f5b215;
}

.mobile-measurement-point-content {
  margin-top: 1%;
  width: 100%;

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0;

    .ant-collapse-arrow {
      transform: scale(-1, 1) translateY(-50%);
    }
  }
}

.mobile-measurement-point-title {
  color: #000000d9;
  background-color: #c9caca;
  font-weight: bold;
  text-align: center;
}

.mobile-measurement-point-body {
  background-color: #c9caca;

  .ant-collapse-header {
    font-weight: bold;
    text-align: center;
  }

  .mobile-measurement-point-list {

    .ant-list-items {
      display: table;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
    }

    .ant-list-split .ant-list-item {
      border-bottom: 0;
      padding: 0;
    }

    /* モバイル端末横向き */
    @media screen and (orientation: landscape) {
      .ant-list.ant-list-split {
        column-count: 2;
      }
    }
  }

  .mobile-measurement-point-list-item {
    text-align: center;

    span {
      margin-right: 3px;
    }
  }
}
