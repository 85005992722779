div.alert-email-container {
  background-color: white;
  min-height: 1026px;
  box-sizing: border-box;
}

.alert-email-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

.alert-email-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
