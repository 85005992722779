.om-dashboard-layout-display-button {
  position: sticky;
  z-index: 20;
  margin-top: 10px;
}

.om-dashboard-tab-items {
  margin-top: -40px;
  z-index: 10;
}

.om-dashboard-tab-items .ant-tabs-ink-bar {
  background-color: #f0f2f5;
}

.om-dashboard-tab-items .ant-tabs-bar {
  border-bottom: none;
  margin: 0;
}

.om-dashboard-tab-items .ant-tabs-nav .ant-tabs-tab {
  pointer-events: none;
}

.om-dashboard-tab-items .ant-tabs-nav-container {
  height: 10px;
}
