.ant-col-6.om-dashboard-pcs-button-column {
  text-align: right;
  margin-right: 5px;
}

.om-dashboard-pcs-measurement-list-button {
  width: 90%;
  margin-right: 10px;
}

.om-dashboard-pcs-measurement-occurrence-button {
  color: #1890ff;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
}

.om-dashboard-pcs-measurement-occurrence-button:hover {
  background-color: #e6f7ff;
}
