.om-dashboard-current-button-col {
  text-align: right;
  margin-right: 5px;
}

.om-dashboard-alert-list-button {
  width: 40%;
  margin-right: 10px;
}

.om-dashboard-alert-history-modal .ant-modal-content {
  width: 1200px;
  height: 800px;
  position: fixed;
  inset: 0;
  margin: auto;
}
