div.common-model-3d-view {
  width: 100%;
  min-width: 800px;
  position: relative;
  top: 0px;
  left: 0px;
}

div.common-model-3d-view div.canvas-wrapper {
  height: 100%;
}

div.common-model-3d-view div.control-panel {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px 70px;
  background-color: rgb(255, 255, 255, 0.4);
}
