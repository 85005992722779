div.portal-container {

  .portal-layout {
    min-width: 800px;

    .portal-header {
      background-color: white;
      padding: 0 20px;

      .portal-logo-text {
        width: 120px;
        height: 100%;
      }

      .portal-top-icon {
        width: 40px;
        margin-left: 10px;
      }

      .portal-mvsw-logo {
        width: 200px;
      }
    }

    .portal-content {
      height: calc(100vh - 64px);

      .portal-top-icon-main {
        width: 200px;
        margin-top: 44px;
        margin-bottom: 10px;
      }

      .portal-version-button {
        text-align: center;
        -webkit-text-stroke: 1px #05F;
      }

      .portal-user-login-button {
        background: 1890ff;
        width: 132px;
        height: 48px;
        font-size: 20px;
      }

      div.portal-contact-items {
        text-align: center;

        .portal-contact-information {
          margin-bottom: 6px;
          font-size: 16px;
        }

        .portal-contact-person {
          margin-bottom: 20px;
          font-size: 16px;
        }
      }
    }
  }
}

.portal-license-modal .ant-modal-body {
  width: 1000px;
  height: 948px;
}

/* ウインドウ幅が500px以下のとき、モバイル端末用のCSSを適用 */
@media screen and (max-width : 500px) {

  div.portal-container {

    .portal-layout {
      min-width: 100%;

      .portal-header {
        height: 64px;

        .portal-logo-text {
          width: 42%;
          height: auto;
        }

        .portal-top-icon {
          width: 14%;
          height: auto;
        }

        .portal-mvsw-logo {
          width: 80%;
          height: auto;
          text-align: right;
        }
      }

      .portal-content {
        display: flex;
        flex-direction: column;

        .portal-top-icon-items {
          max-height: 30%;

          .portal-top-icon-main {
            max-width: 30%;
            max-height: 80%;
            margin-top: 2%;
            margin-bottom: 0;
          }

          .portal-version-button {
            max-height: 18%;
          }

          .portal-version-button .ant-btn {
            font-size: smaller;
          }
        }

        .portal-user-login-button {
          width: 88px;
          height: 32px;
          font-size: 13px;
        }

        div.portal-contact-items {
          margin-top: auto;
          font-size: smaller;

          .portal-contact-information,
          .portal-contact-person,
          .portal-contact-email-address {
            font-size: smaller;
            margin-bottom: auto;
          }
        }
      }
    }
  }

  .portal-license-modal .ant-modal-body {
    width: 95%;
    height: 60vh;
  }

  /* 認証切れ時のモーダル位置補正 */
  .warning-sign-out-modal-content {
    margin: 0 !important;
  }
}

/* モバイル端末横向き */
@media screen and (max-height : 500px) and (orientation: landscape) {

  div.portal-container {

    .portal-layout {
      min-width: 100%;

      .portal-content {
        height: auto;
      }
    }
  }

  .portal-license-modal .ant-modal-body {
    width: 95%;
    height: 60vh;
  }
}
