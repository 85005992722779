.priority {
  width: 74px !important;
}

.task {
  width: 163px !important;
}

.created {
  width: 147px !important;
}

.end {
  width: 147px !important;
}

.model {
  width: 136px !important;
}

.engine {
  width: 154px !important;
}

.class {
  width: 138px !important;
}

.confidence {
  width: 74px !important;
}

.param {
  width: 205px !important;
}

.status {
  width: 123px !important;
}

.borderNone {
  border: none !important;
  background-color: white;
  width: 320px !important;
}
.table {
  margin: 10px;
  overflow: scroll;
  width: 1675px !important;
  table-layout: fixed;
  word-wrap: break-word;
}

.table thead {
  background-color: whitesmoke;
  color: black;
}
.table th, .table td {
  border: 1px solid black;
  width: 180px;
  height: 50px;
  padding-left: 10px;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  background-image: none;
}

.editInput {
  /* border: none !important; */
  width: 90% !important;
  background-color: white !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.hide {
  display: none;
  /* display:block; */
}

