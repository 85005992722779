.ant-card.custom_info .ant-card-body {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 24px;
  padding-right: 24px;
}

.digital-twin-app-ant-btn {
  position: absolute;
  top: 0px;
  right: 0px;
}

.digital-twin-app-ant-card {
  margin-left: 10px;
  margin-top: 40px;
}

.digital-twin-app-play-3D-viewer .ant-btn-link[disabled] {
  color: rgb(217, 217, 217, 0.25);
}
