.class-card-table {
  overflow: scroll;
  width: 500px !important;
  table-layout: fixed;
  word-wrap: break-word;
  margin-top: 4px;
}

.class-card-table .index {
  width: 46px !important;
  padding: 4px 8px 4px 8px !important;
  text-align: center !important;
}

.class-card-table .model {
  width: 400px !important;
  padding: 4px 8px 4px 8px !important;
}

.class-card-table .class {
  width: 200px !important;
  padding: 4px 8px 4px 8px !important;
}

.class-card-table .confidence {
  width: 60px !important;
  padding: 4px 8px 4px 8px !important;
  text-align: center !important;
}

.class-card-table thead {
  background-color: whitesmoke;
  color: black;
}

.class-card-table th, .class-card-table td {
  border: 1px solid black;
}
