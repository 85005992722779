div.financial-report-registration-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

div.financial-report-registration-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

div.financial-report-registration-margin {
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}

.financial-report-registration-annotation {
  color: red;
}

.financial-report-registration .ant-descriptions-item-label {
  width: 400px !important;
  /* ラベルエリアの幅を合わせる */
}
