.filter-component-text-filter-dropdown {
  padding: 8px;
}

.filter-component-text-filter-input {
  margin-bottom: 8px;
  display: block;
  z-index: 1;
}

.filter-component-text-filter-search-button {
  margin-right: 8px;
  width: 100px;
}

.filter-component-text-filter-reset-button {
  width: 100px;
}

.filter-component-file-list-table {
  max-height: 300px;
  overflow-y: auto;
  padding: 4px 0px 4px 0px;
}

.filter-component-file-list-item {
  margin: 0px !important;
  padding: 5px 12px 5px 12px !important;
  display: list-item;
  vertical-align: middle;
  line-height: 22px !important;
  height: 32px !important;
}

.filter-component-filter-list-button {
  margin: 0px;
  padding: 0px;
}

.filter-component-filter-list-button.clear {
  float: right;
}
