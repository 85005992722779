.om-dashboard-stat-display-title {
  font-size: 13px;
  line-height: 1;
  margin-top: 5px;
  text-align: center;
}

div.om-dashboard-stat-display {
  margin: 5px;
  border: solid 1px darkgray;
}

div.om-dashboard-stat-display .ant-card-body {
  margin-bottom: 5px;
  border-radius: 3px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px;
}

div.om-dashboard-stat-display div.om-dashboard-stat-display-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

div.om-dashboard-stat-display-number {
  margin-right: 0;
}

.om-dashboard-stat-display-number .ant-statistic-title {
  font-size: 13px;
}

.om-dashboard-stat-display-number .ant-statistic-content {
  font-size: 21px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
}

.om-dashboard-stat-display-number .ant-statistic-content-value-decimal {
  font-size: 21px;
}
