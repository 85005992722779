.mobile-display-measurement-table {

  .ant-table-thead th,
  .ant-table-tbody td {
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 20ch;
    max-width: 20ch;
    padding: 1% 1%;

    &:first-child {
      width: 80px;
      max-width: 80px;
    }
  }

  .ant-table-thead th div {
    display: block;
    max-width: 20ch;
  }

  .ant-table-header,
  .ant-table-header-column,
  .ant-table-header-column .ant-table-column-sorters::before,
  .ant-table-fixed-left .ant-table-fixed {
    background-color: #e9e9e9 !important;
    font-weight: bold;
  }

  .ant-table-row .ant-table-row-hover {
    background-color: #1890ff33;
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    padding-bottom: 15px !important;
  }

  .mobile-fixed-col {
    position: sticky !important;
    left: 0;
    background: #e9e9e9 !important;
    z-index: 1;
    font-weight: bold;

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
    }
  }
}
