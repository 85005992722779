.enlarge-content-container {
  position: absolute; /* 親要素を元に配置 */
  z-index: 1100; /* AntDesign.Modal(z-index: 1000)よりも上に配置 */
  top: 0px;
  left: 0px;
  background-color: white;
}

.enlarge-content-container .zoom {
  z-index: 1000;
  overflow: hidden;
  margin: 0 auto;
  border: 1px solid #ccc;
  opacity: 0;
}

.enlarge-content-container .zoom.active {
  opacity: 1;
}

.enlarge-content-container .zoom .zoom__elm {
  width: 100%;
}

.enlarge-content-container .zoom .zoom__elm:hover {
  cursor: grab;
}

.enlarge-content-container .zoom .zoom__elm img {
  vertical-align: bottom;
  /* 拡大画像をドラッグできるようにするため、img要素は選択不可 */
  user-select: none;
  pointer-events: none;
}

/* 正方形、横向き */
.enlarge-content-container .zoom .zoom__elm img.square,
.enlarge-content-container .zoom .zoom__elm img.landscape {
  width: 100%;
  height: auto;
}

/* 縦向き */
.enlarge-content-container .zoom .zoom__elm img.portrait {
  width: auto;
  height: 100%;
  max-height: 900px; /* 最大でもエリア内に収める */
}

.enlarge-content-container .zoom .zoom__info {
  position: absolute;
  z-index: 1001;
  width: calc(900px / 5);
  height: calc(900px / 5);
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, .4);
}

.enlarge-content-container .zoom .zoom__info img {
  opacity: .5;
  filter: grayscale(50%);
}

/* 正方形、横向き */
.enlarge-content-container .zoom .zoom__info img.square,
.enlarge-content-container .zoom .zoom__info img.landscape {
  width: 100%;
  height: auto;
}

/* 縦向き */
.enlarge-content-container .zoom .zoom__info img.portrait {
  width: auto;
  height: 100%;
}

.enlarge-content-container .zoom .zoom__area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;
  border: 1px solid #ccc;
  background-color: rgba(0, 0, 0, .3);
}
