/* ファイル名リンクボタン */
.registered-files-table-link-button {
  color: #40a9ff;
  cursor: pointer;
}

div .registered-files-table-col {
  text-align: right;
  z-index: 1;
}

.registered-files-table-reload-button {
  background-color: green !important;
  border: green !important;
  margin: 0px 5px 5px 0px !important;
}

.registered-files-table-pagination {
  margin-right: 5px !important;
}

.registered-files-table-filter-dropdown {
  padding: 8px;
}

.registered-files-table-upload-date-range-picker {
  margin-bottom: 8px;
  display: block;
}

.registered-files-table-search-button {
  margin-right: 8px;
  width: 90px;
}

.registered-files-table-reset-button {
  width: 90px;
}

.registered-files-table-file-name-input {
  margin-bottom: 8px;
  display: block;
  z-index: 1;
}
