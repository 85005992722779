.om-dashboard-group-setting-modal .ant-modal-content {
  width: 600px;
  height: 730px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.group-setting-modal-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

.group-setting-modal-group-name-input {
  margin: 5px;
}

.group-setting-modal-email-address-input {
  margin: 5px;
}

.group-setting-modal-table {
  margin: 5px;
  width: 530px;
  height: 450px;
  margin-top: 10px;
}

.group-setting-modal-update-button {
  margin: 5px;
  text-align: right;
  margin-top: 13px;
}
