div.dashboard-container {
  width: 100%;
  height: 100%;
  padding: 10px;
}

div.dashboard-container div.statistic {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: solid 1px darkgray;
  border-radius: 5px;
  background-color: white;
}
