.alert-setting-modal-update-button {
  margin-top: 10px;
  text-align: right;
}

.alert-setting-modal-input-spacing {
  margin-bottom: 10px;
}

.alert-setting-modal-group-pulldown .ant-select-enabled {
  width: 100%;
  height: 60px;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
}

.alert-setting-modal-group-pulldown .ant-select-selection {
  border: none;
}

.alert-setting-modal-input-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
