div.common-file-content {
  position: 'relative';
  top: 0px;
  left: 0px;
  overflow: scroll;
}

div.common-file-content div.message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

div.common-file-content img {
  width: 800px;
  height: 600px;
  box-sizing: border-box;
}

div.common-file-content div.slider {
  width: 200px;
  height: 30px;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0px 5px;
  background-color: rgb(255, 255, 255, 0.4);
}

div.common-file-content div.link {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255, 0.4);
}
