.group-setting-table-filter-dropdown {
  padding: 8px;
}

.group-setting-table-filter-input {
  margin-bottom: 8px;
  display: block;
  z-index: 1;
}

.group-setting-table-filter-search-button {
  margin-right: 8px;
  width: 100px;
}

.group-setting-table-filter-reset-button {
  width: 100px;
}

.group-setting-container {
  background-color: white;
  min-height: 1026px;
}

.group-setting-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

.group-setting-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

.group-setting-add-button {
  margin: 10px;
  text-align: right;
}

.group-setting-table {
  margin: 10px;
  width: 99%;
}
