.mobile-om-dashboard-alert-occurrence-content:has(div) {
  margin-top: 1%;

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0;

    .ant-collapse-arrow {
      color: #ffffff;
      left: auto;
      right: 16px;
      transform: scale(-1, 1) translateY(-50%);
    }
  }

  .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
  }

  .mobile-om-dashboard-alert-occurring-items {

    .ant-list.ant-list-split {
      column-count: 1;

      /* モバイル端末横向きの場合は2列表示 */
      @media screen and (orientation: landscape) {
        column-count: 2;
      }
    }

    .ant-list-items {
      display: table;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
    }

    .ant-list-split .ant-list-item {
      border-bottom: 0;
      padding: 0;
    }
  }

  .mobile-om-dashboard-alert-occurrence-bar {
    width: 100%;
    background-color: #ff0000;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-om-dashboard-alert-occurrence-text {
    font-size: 14px;
    color: #ffffff;
  }

  .mobile-om-dashboard-alert-occurring-items {
    border: solid 1px #ff0000;
    background-color: #ffffff;
  }

  .mobile-om-dashboard-alert-occurrence-red-style-circle {
    color: #ff0000;
    margin-right: 3px;
  }

  .mobile-om-dashboard-alert-occurrence-yellow-style-circle {
    color: #f5b215;
    margin-right: 3px;
  }
}
