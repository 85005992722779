div.common-file-list {
  height: 200px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: solid 1px lightgray;
  overflow: scroll;
}

div.common-file-list.disabled {
  cursor: not-allowed;
}

div.common-file-list .selected {
  color: white;
  background-color: rgb(53, 159, 254);
}

div.common-file-list .not-selected {
  color: black;
  background-color: white;
}

div.common-file-list .disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgb(245, 245, 245);
}
