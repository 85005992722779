.mobile-display-layout {
  @media (orientation: portrait) {
    --mobile-display-layout-menu-height: max(5vh, 46.6px);
  }

  @media (orientation: landscape) {
    --mobile-display-layout-menu-height: max(10vh, 43px);
  }

  --mobile-display-layout-icon-size: calc(0.5 * var(--mobile-display-layout-menu-height));

  height: 100vh;
  width: 100vw;
  overflow: auto;
  align-items: stretch;

  .ant-layout-header {
    padding: 0;
    height: var(--mobile-display-layout-menu-height);
    width: 100%;

    .ant-menu.ant-menu-root {
      height: 100%;
    }
  }

  .ant-layout-content {
    height: calc(100% - var(--mobile-display-layout-menu-height));

    div.om-dashboard-common-facility-tree-ant-spin {
      width: 100vw;
      max-height: calc(100vh - var(--mobile-display-layout-menu-height));
    }
  }
}

.mobile-menu-bar li.ant-menu-submenu {
  height: var(--mobile-display-layout-menu-height);
  width: 100%;
  position: absolute;
  z-index: 100;

  .ant-menu-submenu-title {
    margin: 0;
    height: 100%;
    line-height: var(--mobile-display-layout-menu-height);
    pointer-events: none;

    &::after {
      display: none !important;
    }
  }

  ul {
    min-height: var(--mobile-display-layout-menu-height);
  }

  li.ant-menu-item {
    margin: 0;
    height: var(--mobile-display-layout-menu-height);
    line-height: var(--mobile-display-layout-menu-height);
  }

  .anticon-menu {
    pointer-events: auto;
    vertical-align: middle;

    svg {
      height: var(--mobile-display-layout-icon-size);
      width: var(--mobile-display-layout-icon-size);
    }
  }

  .ant-menu-submenu-arrow {
    display: none;
  }
}

.mobile-menu-logout-button {
  background-color: #001529;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;
  height: var(--mobile-display-layout-menu-height);
  width: var(--mobile-display-layout-menu-height);

  &:focus {
    background: #001529;
  }

  &:hover {
    background: #001529;
  }

  img {
    margin: 0;
    height: var(--mobile-display-layout-icon-size);
    width: var(--mobile-display-layout-icon-size);
  }
}

.mobile-menu-logout-modal div.ant-modal {
  margin: 0 auto;
  top: 20vh;
  max-height: 75vh;
  max-width: min(372px, 85vw);

  .ant-modal-body {
    font-size: 16px;
  }

  .ant-modal-footer {
    padding: 10px 10%;

    div {
      display: flex;
      justify-content: space-around;

      button {
        width: 30%;
        padding: 0;

        span {
          text-align: center;
        }
      }
    }
  }
}
