.om-dashboard-layout-display-modal .ant-modal-content {
  width: 900px;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.om-dashboard-layout-display-modal .swiper {
  width: 900px;
  height: 440px;
  overflow: hidden;
}

.om-dashboard-layout-display-modal .swiper-wrapper img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: center;
}

.om-dashboard-layout-display-modal .swiper {
  height: 510px;
}

.om-dashboard-layout-display-modal .swiper-wrapper {
  margin-top: 30px;
}
