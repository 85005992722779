.activity-check-mail-setting-display-modal .ant-modal-content {
  width: 1870px;
  height: 930px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.activity-check-mail-setting-display-modal .ant-modal-body {
  width: 95%;
}

.activity-check-mail-setting-modal-target-site-table {
  height: 500px;
}

.activity-check-mail-setting-modal-add-button {
  text-align: right;
  align-items: center;
  margin-top: 1px;
}

.solar-electric-activity-check-mail-send-setting-container {
  background-color: white;
  min-height: 480px;
}

.email-notification-group-settings-modal-select .ant-select-enabled {
  width: 50%;
  height: 80px;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
}

.email-notification-group-settings-modal-select .ant-select-selection {
  border: none;
}

.email-notification-group-settings-modal-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
