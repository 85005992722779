b.total-number-title {
  font-size: 22px;
}
div.total-number-wrap {
  display: flex;
}

div.total-number-wrap div.total-number {
  margin-right: 50px;
}
