.mobile-om-equipment-tree-button {
  width: 80vw;
  text-align: left;
}

.mobile-om-equipment-tree-button-no-select {
  color: #bfbfbf;
  width: 80vw;
  text-align: left;
}

.mobile-om-equipment-tree-drawer {
  @media (orientation: portrait) {
    --mobile-om-tree-top: max(5vh, 46.6px);
  }

  @media (orientation: landscape) {
    --mobile-om-tree-top: max(10vh, 43px);
  }

  top: var(--mobile-om-tree-top);
  height: calc(100vh - var(--mobile-om-tree-top));
  z-index: 99;

  .ant-drawer-content-wrapper {
    width: 80vw;

    .ant-drawer-body {
      padding: 10px 24px calc(1.2 * var(--mobile-om-tree-top));
    }
  }
}

.mobile-om-equipment-tree-title-disabled {
  color: #00000040;
}

.mobile-om-equipment-tree-title-red {
  color: #ff0000;
}

.mobile-om-equipment-tree-title-black {
  color: #000000;
}
