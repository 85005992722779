span #tooltip {
  position: fixed;
  z-index: 10;
  visibility: hidden;
  padding: 0 5px;
  background-color: #FFF;
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}
