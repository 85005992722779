div.ant-modal.mobile-om-dashboard-layout-display-modal {
  width: auto !important;
  padding-bottom: 0;
  margin: 0;
}

.mobile-om-dashboard-layout-display-modal .ant-modal-content {
  width: 90vw;
  height: calc(90vw*0.6);
  max-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-body {
    padding: 0;
  }
}

.mobile-om-dashboard-layout-display-modal .swiper {
  width: 90vw;
  height: calc(90vw*0.6);
  max-height: 90vh;
  overflow: hidden;
}

.mobile-om-dashboard-layout-display-modal .swiper-wrapper img {
  height: 90%;
  margin: auto;
  margin-top: 2.5%;
  display: flex;
  justify-content: center;
}
