.om-dashboard-alert-occurrence-bar {
  width: 100%;
  background-color: red;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.om-dashboard-alert-occurrence-text {
  font-size: 14px;
  color: white;
}

.om-dashboard-alert-occurring-items {
  border: solid 1px red;
  background-color: white;
  padding: 10px 180px;
}

.om-dashboard-alert-occurrence-red-style-circle {
  color: red;
}

.om-dashboard-alert-occurrence-yellow-style-circle {
  color: rgb(245, 178, 21);
}
