.standard-pcs-setting-table {
  margin: 10px;
  width: 99%;
}

div.standard-pcs-setting-container {
  background-color: white;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
}

.standard-pcs-setting-title {
  margin-top: 15px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

.standard-pcs-setting-title-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
