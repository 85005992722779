.ant-menu-item .menu-item,
.ant-menu-submenu-title .menu-item {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 28px;
}

.ant-menu {
  font-family: sans-serif, "メイリオ";
}

.submenu-text {
  font-size: 13px;
}
