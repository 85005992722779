.mobile-common-header-content {
  width: 98%;
  margin: auto;
}

.ant-row.mobile-common-header-stat-display {
  margin-left: 0 !important;
  margin-right: 0 !important;

  .ant-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.mobile-common-header-tab-items {

  .ant-tabs-top-bar {
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    height: auto;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    z-index: 0;
  }
}

.mobile-common-header-button-items {
  line-height: 0;
}
