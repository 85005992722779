div.electric-energy-history-container {
  background-color: white;
  min-height: 1080px;
}

.electric-energy-history-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

.electric-energy-history-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

.electric-energy-history-annotation {
  color: red;
}
