div.ant-row.ant-form-item:last-child {
  margin-bottom: 0px;
}

.footerButtonContainer {
  text-align: center;
}

.footerButtons {
  width: 150px;
}
