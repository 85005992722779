.om-dashboard-facility-floor-name-title {
  margin-bottom: 5px;
  border-radius: 3px;
  border: solid 1px white;
  background-color: #c9caca;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px;
  width: 30%;
  font-weight: bold;
}

.om-dashboard-facility-name-list-title {
  float: right;
  margin-bottom: 5px;
  border-radius: 3px;
  padding: 5px 5px;
  border: solid 1px white;
  background-color: #c9caca;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  width: 69%;
}

div.om-dashboard-subject-facility-title {
  margin-bottom: 5px;
  border-radius: 3px;
  padding: 5px 5px;
  border: solid 1px white;
  background-color: #c9caca;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  width: 100%;
}
