.mobile-om-dashboard-stat-display-title {
  font-size: 13px;
  line-height: 1;
  margin-top: 5px;
  text-align: center;
}

.mobile-om-dashboard-stat-display {
  margin: 1% 0 0 0;
  border: solid 1px #a8a8a8;

  .ant-card-body {
    margin-bottom: 5px;
    border-radius: 3px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
  }

  .mobile-om-dashboard-stat-display-content {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
}

div.mobile-om-dashboard-stat-display-number {
  margin-right: 0;

  .ant-statistic-title {
    font-size: 13px;
  }

  .ant-statistic-content {
    font-size: 21px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1;
  }

  .ant-statistic-content-value-decimal {
    font-size: 21px;
  }
}

.mobile-om-dashboard-stat-content {

  [class^="swiper-button"] {
    /* Swiperの矢印ボタン「<」「>」のサイズ修正、SwiperのnavigationのCSSにて定義 */
    --swiper-navigation-size: 22px;
  }

  [class^="swiper-button"].swiper-button-disabled {
    display: none;
  }
}
