div.solar-operational-status-input-container {
  background-color: white;
  min-height: 980px;
}

div.solar-operational-status-input-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

div.solar-operational-status-input-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

div.solar-operational-status-input-margin {
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}
