.mobile-measurement-value-content {
  margin-top: 1%;
  width: 100%;

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 0;

    .ant-collapse-arrow {
      transform: scale(-1, 1) translateY(-50%);
    }
  }
}

.mobile-measurement-value-title {
  color: #000000d9;
  background-color: #c9caca;
  font-weight: bold;
  text-align: center;
}

.mobile-measurement-value-body {
  background-color: #c9caca;

  .ant-collapse-header {
    font-weight: bold;
    text-align: center;
  }

  .ant-descriptions-item-label {
    background-color: #c9caca;
    font-weight: bold;
    width: 65%;
    padding: 1%;
  }

  .ant-descriptions-item-content {
    padding: 1%;
  }

  .ant-descriptions-bordered {
    border: none;

    .ant-descriptions-view {
      border-bottom: none;
    }
  }

  .ant-descriptions-row {
    border: solid 1px #000000 !important;
  }

  /* iPhone(実機)での表示時に、Descriptions(Ant Design)の最終行右端の枠線が削除されるため
  下記のCSSを適用 */
  .ant-descriptions-bordered .ant-descriptions-item-label:last-child,
  .ant-descriptions-bordered .ant-descriptions-item-content:last-child {
    border-right: solid 1px #000000 !important;
  }
}
