span.menu-text {
  font-size: 13px;
  color: white;
  vertical-align: bottom;
  margin-left: 20px;
  margin-right: 20px;
}

img.anticon {
  vertical-align: middle;
}

.sub-menu-row-without-icon {
  color: white;
}
