.contain-modal-image {
  object-fit     : contain;              /* BOX内の画像配置     */
  width          : 100%;                 /* BOXの幅             */
  height         : 900px;                /* BOXの高さ           */
  background     : whitesmoke;           /* BOXの背景色         */
}

.contain-modal-json {
  text-align     : left;                 /* 横位置              */
  width          : 100%;                 /* BOXの幅             */
  height         : 900px;                /* BOXの高さ           */
  background     : whitesmoke;           /* BOXの背景色         */
}

.contain-modal-pdf {
  object-fit     : contain;              /* BOX内の画像配置     */
  width          : 100%;                 /* BOXの幅             */
  height         : 900px;                /* BOXの高さ           */
  background     : whitesmoke;           /* BOXの背景色         */
}

.contain-modal-text {
  text-align     : left;                 /* 横位置              */
  width          : 100%;                 /* BOXの幅             */
  height         : 900px;                /* BOXの高さ           */
  background     : whitesmoke;           /* BOXの背景色         */
}

.contain-modal-video {
  object-fit     : contain;              /* BOX内の画像配置     */
  width          : 100%;                 /* BOXの幅             */
  height         : 900px;                /* BOXの高さ           */
  background     : whitesmoke;           /* BOXの背景色         */
}

.contain-modal-unknown {
  display        : flex;                 /* レイアウト          */
  justify-content: center;               /* アイテムの水平位置   */
  align-items    : center;               /* アイテムの垂直位置   */
  white-Space    : pre-line;             /* テキスト自動改行     */
  width          : 100%;                 /* BOXの幅             */
  height         : 900px;                /* BOXの高さ           */
  background     : whitesmoke;           /* BOXの背景色         */
}
