.interval-pulldown {
  width: 150px;
}

.update-button {
  text-align: right;
}

.input-spacing {
  margin-bottom: 10px;
}
