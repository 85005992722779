/** アップロードファイル一覧タイトルコンテナ */

div.registered-files-list-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

div.registered-files-list-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

div.registered-files-list-margin {
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}
