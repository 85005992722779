div.common-metadata-table-button-group {
  margin-top: 10px;
}

div.common-metadata-table-uneditable-cell {
  padding: 5px 12px;
  cursor: pointer;
}

div.common-metadata-table-editable-cell {
  padding: 8px 12px;
  cursor: pointer;
}

div.common-metadata-table-editable-cell:hover {
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  padding: 7px 11px;
}
