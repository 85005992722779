div.scan-edit-form-footer {
  width: 100%;
  height: 50px;
  position: relative;
  top: 0px;
  left: 0px;
}

div.scan-edit-form-footer div.delete-button {
  position: absolute;
  top: 0px;
  left: 0px;
}
