.email-notification-group-settings-add-button {
  margin: 10px;
  text-align: right;
}

.email-notification-group-settings-table {
  margin: 10px;
  width: 1650px;
}

.email-notification-group-settings-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

.email-notification-group-settings-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

.email-notification-group-settings-container {
  background-color: white;
  min-height: 1026px;
}
