.om-dashboard-measurement-value-title {
  margin-bottom: 2px;
  border-radius: 3px;
  padding: 5px 5px;
  border: solid 1px white;
  background-color: #c9caca;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  width: 100%;
}

.om-dashboard-measurement-value-list {
  float: left;
  width: 100%;
}

.om-dashboard-measurement-value-card .ant-card-head {
  background-color: #c9caca;
  font-weight: bold;
  border: solid 1px black;
  font-size: 13px;
  font-family: 'メイリオ';
  flex-direction: column;
  text-align: center;
  justify-content: center;
  display: flex;
}

.om-dashboard-measurement-value-card .ant-card-body {
  border: solid 1px black;
  font-size: 13px;
  font-family: 'メイリオ';
  height: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  display: flex;
}
