div.equipment-information-container {
  background-color: white;
  min-height: 1080px;
}

.equipment-information-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

.equipment-information-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

