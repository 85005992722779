.om-dashboard-facility-name-list {
  float: right;
  width: 69%;
}

.om-dashboard-facility-floor-name {
  font-size: 14px;
  margin-bottom: 5px;
  border-radius: 3px;
  padding: 5px 5px;
  border: solid 1px darkgray;
  background-color: white;
  flex-direction: column;
  width: 30%;
  text-align: center;
  font-weight: bold;
  top: 0;
  bottom: 0;
  margin: auto;
  justify-content: center;
  display: flex;
}

.om-dashboard-facility-name-card .ant-card-head {
  background-color: #c9caca;
  font-weight: bold;
  border: solid 1px black;
  font-size: 13px;
}

.om-dashboard-facility-name-card .ant-card-body {
  border: solid 1px black;
  font-size: 13px;
}

.om-dashboard-facility-floor-name-element {
  text-align: center;
}
