.searched-event-table-pagination {
  margin-right: 5px !important;
}

.searched-event-table-filter-dropdown {
  padding: 8px;
}

.searched-event-table-upload-date-range-picker {
  margin-bottom: 8px;
  display: block;
}

.searched-event-table-search-button {
  margin-right: 8px;
  width: 90px;
}

.searched-event-table-reset-button {
  width: 90px;
}

.searched-event-table-site-name-input {
  margin-bottom: 8px;
  display: block;
  z-index: 1;
}

.searched-event-table-pcs-number-input {
  margin-bottom: 8px;
  display: block;
  z-index: 1;
}
