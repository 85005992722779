.standard-pcs-pulldown {
  width: 150px;
}

.standard-pcs-update-button {
  text-align: right;
}

.standard-pcs-input-spacing {
  margin-bottom: 10px;
}

.standard-pcs-input-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
