div.financial-report-search-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

div.financial-report-search-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

div.financial-report-search-margin {
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}

.financial-report-search-annotation {
  color: red;
}
