.image-gallery-container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.image-gallery-container .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: whitesmoke;
}

.image-gallery-container .swiper-slide {
  background-size: cover;
  background-position: center;
}

.image-gallery-container .mainGallery {
  height: 760px;
  width: 1013px;
  margin-left: auto;
  margin-right: auto;
}

.image-gallery-container .thumbnailGallery {
  height: 760px;
  width: 1013px;
  box-sizing: border-box;
  padding: 10px 0;
  height: 10rem;
}

.image-gallery-container .thumbnailGallery .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1.0;
}

.image-gallery-container .thumbnailGallery .swiper-slide-thumb-active {
  opacity: 1.0;
  border: 2px solid rgb(0, 0, 255);
}

.image-gallery-container .image-name img {
  position: relative;
  padding: 10px 10px;
  margin-left: auto;
  margin-right: auto;
  width: 118px;
  height: 118px;
  background-color: white;
  text-align: center;
}

.image-gallery-container .image-name p {
  position: absolute;
  height: 15px;
  top: 88%;
  left: 8px;
  margin: 0;
  color: black;
  background-color: white;
  font-size: 10px;
  line-height: 1;
  padding: 3px 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 17ch;
  font-family: "メイリオ";
  max-width: 122px;
}


.image-gallery-container .mainGalleryCompare {
  height: 684px;
  width: 912px;
  margin-left: auto;
  margin-right: auto;
}

.image-gallery-container .thumbnailGalleryCompare {
  height: 684px;
  width: 912px;
  box-sizing: border-box;
  padding: 10px 0;
  height: 10rem;
}

.image-gallery-container .thumbnailGalleryCompare .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1.0;
}

.image-gallery-container .thumbnailGalleryCompare .swiper-slide-thumb-active {
  opacity: 1.0;
  border: 2px solid rgb(0, 0, 255);
}

.image-gallery-container .image-name-compare img {
  position: relative;
  padding: 10px 10px;
  margin-left: auto;
  margin-right: auto;
  width: 104px;
  height: 104px;
  background-color: white;
  text-align: center;
}

.image-gallery-container .image-name-compare p {
  position: absolute;
  height: 14px;
  top: 88%;
  left: 1px;
  margin: 0;
  color: black;
  background-color: white;
  font-size: 10px;
  line-height: 1;
  padding: 3px 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 17ch;
  font-family: "メイリオ";
  max-width: 108px;
}

.image-gallery-container .zoom-icon {
  cursor: pointer;
}

.image-gallery-container .zoom-icon.enlarge {
  z-index: 1000;
  position: absolute;
  right: 30px;
}

.image-gallery-container .zoom-icon.shrink {
  z-index: 1110;
  position: absolute;
  top: 0px;
  right: 30px;
}
