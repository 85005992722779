.file-list-table {
  max-height: 300px;
  overflow-y: auto;
  padding: 4px 0px 4px 0px;
}

.file-list-item {
  margin: 0px !important;
  padding: 5px 12px 5px 12px !important;
  display: list-item;
  vertical-align: middle;
  line-height: 22px !important;
  height: 32px !important;
}

.filter-list-button {
  margin: 0px;
  padding: 0px;
}

.filter-list-button.clear {
  float: right;
}

.file-table-thumbnail {
  object-fit: contain;
  /* BOX内の画像配置 */
  width: 80px;
  /* BOXの幅 */
  height: 60px;
  /* BOXの高さ */
}

.file-table-file-name-button {
  display: inline-block;
  /* インラインブロック要素にする */
  vertical-align: middle;
  /* 縦位置 */
  margin-right: 10px;
  /* 幅が最大値以上の場合三点リーダーにする */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 設備詳細ーアップロードファイルタブのファイル名最大幅 */
.digital-twin-app-upload-file-name {
  /* 全角25文字 */
  max-width: 25em;
}

/* 設備詳細ーAI検出結果タブのファイル名最大幅 */
.digital-twin-app-detection-result-file-name {
  /* 全角10文字 */
  max-width: 10em;
}

/* アノテーションのファイル名最大幅 */
.annotation-file-name {
  /* 全角15文字 */
  max-width: 15em;
}

/* 機械学習のファイル名最大幅 */
.learning-file-name {
  /* 全角15文字 */
  max-width: 15em;
}

/* AI検出のファイル名最大幅 */
.detect-file-name {
  /* 全角25文字 */
  max-width: 25em;
}

/* AI検出結果のファイル名最大幅 */
.detect-result-file-name {
  /* 全角10文字 */
  max-width: 10em;
}
