.diagnosis-setting-tabs .ant-tabs-top-bar {
  background-color: white;
  margin: 0;
  padding: 0;
  height: auto;
}

.diagnosis-setting-tabs-background-color {
  background-color: white;
}
