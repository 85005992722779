div.operational-status-list-container {
  background-color: white;
  min-height: 1080px;
}

div.operational-status-delete-container {
  background-color: white;
  min-height: 980px;
}

.operational-status-list-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

.operational-status-list-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}

.operational-status-list-annotation {
  color: red;
}
