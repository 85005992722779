div.dashboard-monitor {
  width: 100%;
  display: flex;
}

div.dashboard-monitor div.timeseries-tree {
  width: 400px;
  padding-top: 8px;
  margin-right: 10px;
  background-color: white;
}

div.dashboard-monitor div.chart-container {
  width: 100%;
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
}
