div.om-dashboard-common-facility-tree-node {
  display: flex;
}

div.om-dashboard-common-facility-tree-node div.name {
  max-width: 400px;
  height: 32px;
}

div.om-dashboard-common-facility-tree-node div.disable {
  color: rgba(0, 0, 0, 0.25);
}

div.om-dashboard-common-facility-tree-node div.button-group {
  width: 100px;
  text-align: right;
  margin-left: auto;
}

div.om-dashboard-common-facility-tree-ant-title {
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
}

div.om-dashboard-common-facility-tree-ant-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
