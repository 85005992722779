.solar-email-setting-background-color .ant-tabs-top-bar {
  background-color: white;
  margin: 0;
  padding: 0;
  height: auto;
}

.solar-email-setting-background-color {
  background-color: white;

}
